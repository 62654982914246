import { useState, useEffect, useRef, memo } from "react";
import { ShowMoreButton } from "../components/ShowMoreButton";
import { TestFox } from "../components/TestFox";
import TwinFinder from "./TwinFinder";
import { PulseLoader } from 'react-spinners';
import { Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { ThreeCircles } from 'react-loader-spinner';

type DisplayPlayersArrayProps = {
  playersarray: PlayersArray[],
  chaserid: string
}

type PlayersArray = {
  id: string,
  owneraddress: string,
  outpoint: string,
  foxname: string,
  image: string,
  imagelink: string,
  x: number,
  y: number,
  speed: number,
  height: number,
  width: number,
  dir: number
}



const DisplayPlayersArray = memo(function DisplayPlayersArray({ playersarray, chaserid }: DisplayPlayersArrayProps) {

    //useRefs
    const didMount = useRef(false);
    // get tagged foxes
    useEffect(() => {
    }, []);

  return (
    <>
    {!playersarray &&

      <>
        <div className="activity-left">
          There are no players!
        </div>
      </>
    }
    {playersarray &&

      <>
        <ul id="players-container">
          {playersarray.map(function (data) {
           // console.log("map players array")
            if (data.id === chaserid) {

              return (
                <li key={uuidv4()}><a target="blank"
                  href={data.imagelink}>
                  <img src={data.image}
                    className="ItX"
                    id={data.outpoint} />
                </a>
                  <br />

                  <span className="TwinName"><a className="Taggee" target="blank"
                    href={data.imagelink}>{data.foxname}</a>
                  </span>

                </li>
              )
            } else {
              return (
                <li key={uuidv4()}><a target="blank"
                  href={data.imagelink}>
                  <img src={data.image}
                    className="NotItX"
                    id={data.outpoint} />
                </a>
                  <br />

                  <span className="TwinName"><a target="blank"
                    href={data.imagelink}>{data.foxname}</a>
                  </span>

                </li>
              )
            }
          })}
        </ul>
      </>
    }
    </>
  )
});

export default DisplayPlayersArray;