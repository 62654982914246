import "./App.css";
import { Foxplorer } from "./page/Foxplorer";
import { Live } from "./page/Live";
import { Tag } from "./page/Tag";
import { PrivacyPolicyAndTermsOfService } from "./page/PrivacyPolicyAndTermsOfService";
import { FoxFaucet } from "./page/FoxFaucet";
import { DemoFoxes } from "./page/DemoFoxes";
import { FaucetFoxes } from "./page/FaucetFoxes";
import { CommunityFaucet } from "./page/CommunityFaucet";
import { LiveWithFox } from "./page/LiveWithFox";
import { TagWithFox } from "./page/TagWithFox";
import FoxGroups from "./page/FoxGroups";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { PickAFox } from "./page/PickAFox";


export const App = () => {

  return (

    <>
      <Router>
        <Routes>

          <Route
            path="/"
            element={<Foxplorer />}
          />

          <Route
            path="/privacy-policy-and-terms-of-service"
            element={<PrivacyPolicyAndTermsOfService />}
          />

          <Route
            path="/faucet"
            element={<CommunityFaucet />}
          />

          <Route
            path="/live"
            element={<Live />}
          />

<Route
            path="/tag"
            element={<Tag />}
          />

          <Route
            path="/pickafox"
            element={<PickAFox />}
          />      

          <Route
            path="/livewithfox"
            element={<LiveWithFox />}
          />    
                    <Route
            path="/tagwithfox"
            element={<TagWithFox />}
          />    

          <Route
            path="/demofoxes"
            element={<DemoFoxes />}
          />
                    <Route
            path="/faucetfoxes"
            element={<FaucetFoxes />}
          />

        </Routes>
      </Router>
    </>
  );
}