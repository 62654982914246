import { FoxplorerLogo } from "../components/FoxplorerLogo";
import { XLogoRight } from "../components/XLogoRight";
import Footer from "../components/Footer";
import FooterHome from "../components/FooterHome";
import TemporaryDrawer from "../components/Drawer";


export const PrivacyPolicyAndTermsOfService = () => {
  //go home 
  const goToIndex = async () => {
    window.location.href = "https://foxplorer.org/";
  };

  //return jsx
  return (
    <div className="AppPP">
              <TemporaryDrawer />
      <div className="Topbar">
        <FoxplorerLogo onClick={goToIndex} />
        <a target="blank" href="https://twitter.com/yours_foxplorer"><XLogoRight /></a>
      </div>


      <div className="Wrapper">

        <p className="PP">
          <h3 className="Blue">Privacy Policy</h3>
          https://foxplorer.org is currently beta testing. You are using this site at your own risk.<br /><br />
          This Privacy Policy is reviewed and revised from time to time. You will want to revisit it regularly. Your use of this site, in any and all forms, constitutes acceptance of this Privacy Policy.<br /><br />
          The purpose of https://foxplorer.org is to help you interact with the Bitcoin (BSV) public blockchain through your non-custodial Yours Wallet brower extension.<br /><br />
          Assume everything you do on https://foxplorer.org will forever be public knowledge.<br /><br />
          1. Data Collected:<br /><br />
          https://foxplorer.org uses the public Bitcoin (BSV) blockchain for data storage and retrieval. All your wallet transactions are publicly available to anyone forever.<br /><br />
          All gameplay data is also stored publicly on the Bitcoin (BSV) blockchain. This data includes players' ordinals addresses, time of game data transaction, descriptions of in-game events, and more.<br /><br />
          2. Log Files<br /><br />
          https://foxplorer.org doesn't keep log files,  but integrates with Yours Wallet and is hosted on Cloudflare, so be sure you also accept their privacy policies and terms below.<br /><br />
          Yours Wallet's privacy policy, terms of service and more:<br />
          <a target="blank" href="https://github.com/yours-org/yours-wallet"><u>Yours.org GitHub</u></a><br /><br />
          Cloudflare's privacy policy, terms of service and more:<br />
          <a target="blank" href="https://www.cloudflare.com/privacypolicy/"><u>Cloudflare Privacy Policy</u></a><br />
          <a target="blank" href="https://www.cloudflare.com/website-terms/"><u>Cloudflare Terms of Use</u></a><br /><br />
          If you have any questions, please email info@foxplorer.org<br /><br />
          This privacy policy is subject to change without notice. Please check back frequently for any updates.<br /><br />
          Last Updated: September 19th, 2024
        </p>
        <br />
        <p className="PP">
          <h3 className="Blue">Terms of Service</h3>
          https://foxplorer.org is currently beta testing. You are using this site at your own risk.<br /><br />
          The purpose of https://foxplorer.org is to help you interact with the Bitcoin (BSV) public blockchain through your non-custodial Yours Wallet brower extension.<br /><br />
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, https://foxplorer.org SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES OR LOSSES, WHETHER TANGIBLE OR INTANGIBLE, RESULTING FROM AUTHORIZED OR UNAUTHORIZED USE OF OR ACCESS TO OUR PRODUCT<br /><br />
          If you have any questions, please email info@foxplorer.org<br /><br />
          These terms of service are subject to change without notice. Please check back frequently for any updates.<br /><br />
          Last Updated: September 19th, 2024
        </p>
        <br />
        <a className="Back" href="https://foxplorer.org/"><u>Back to home</u></a>
        <br />
<FooterHome />
      </div>
    </div>
  );
};