import React, { useState, useEffect, useRef, useMemo } from "react";
import { TagLogo } from "../components/TagLogo";
import { ThreeCircles } from 'react-loader-spinner';
import { XLogoRight } from "../components/XLogoRight";
import FooterHome from "../components/FooterHome";
import { ExitButton } from "../components/ExitButton";
import { AiOutlineEye } from "react-icons/ai";
import TemporaryDrawer from "../components/Drawer";
import ActivityTable from "../components/ActivityTable";
import TaggedFoxes from "../components/TaggedFoxes";
import DisplayPlayersArray from "../components/DisplayPlayersArray";
import { WeLive } from "../components/Live";
import {
  usePandaWallet,
  Addresses
} from "panda-wallet-provider";
import { FaucetPandaConnectButton } from "../components/FaucetPandaConnectButton";
//socketio

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import { io } from 'socket.io-client';
import type { Socket } from 'socket.io-client';
//sounds
import boom from "../assets/boom.mp3";
import rumble from "../assets/spacerumblesoundtrack.mp3";
import { ImVolumeMute2 } from "react-icons/im";
import { ImVolumeHigh } from "react-icons/im";


type TagProps = {

}

type ActivityArray = {
  taggerowneraddress: string,
  taggeroutpoint: string,
  taggerfoxname: string,
  taggerimage: string,
  taggerimagelink: string,
  taggeeowneraddress: string,
  taggeeoutpoint: string,
  taggeefoxname: string,
  taggeeimage: string,
  taggeeimagelink: string,
  time: string,
  txid: string
}

type PlayersArray = {
  id: string,
  owneraddress: string,
  outpoint: string,
  currentoutpoint: string,
  foxname: string,
  image: string,
  imagelink: string,
  x: number,
  y: number,
  speed: number,
  height: number,
  width: number,
  dir: number
}

export const Tag = ({ }: TagProps) => {

    //use yours
    const wallet = usePandaWallet();
    const [pubKey, setPubKey] = useState<string | undefined>();
    const [addresses, setAddresses] = useState<Addresses | undefined>();
    const [myordaddress, setMyOrdAddress] = useState<string>("");
    const [ordaddress, setOrdAddress] = useState<string>();
  

  //loading for twins
  const [loading, setLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  // const [myordaddress, setMyOrdAddress] = useState<string>("");

  //owns a fox
  const [ownsfox, setOwnsFox] = useState<boolean>(false);
  const [foxesowned, setFoxesOwned] = useState<number | string>("?");
  const [foxname, setFoxName] = useState<string>("");
  const [foximagesrc, setFoxImageSrc] = useState<string>();
  const [foximageurl, setFoxImageUrl] = useState<string>();
  const [foximageinfo, setFoxImageInfo] = useState<string>();


  //useRefs
  const didMount = useRef(false);
  const didMount1 = useRef(false);
  const didMount2 = useRef(false);
  const didMount3 = useRef(false);
  const didMount4 = useRef(false);
  const didMount5 = useRef(false);
  const didMount6 = useRef(false);
  //tag vars
  const [igottagged, setIGotTagged] = useState<boolean>(false);
  const [showinfo, setShowInfo] = useState<boolean>(true);
  const [showmuted, setShowMuted] = useState<boolean>(true);
  const [hidemuted, setHideMuted] = useState<boolean>(false);
  const [showleaving, setShowLeaving] = useState<boolean>(false);
  const [viewingtaggedinfo, setViewingTaggedInfo] = useState<boolean>(false);
  const [viewers, setViewers] = useState<number | string>('')
  const [players, setPlayers] = useState<number | string>(0)
  const [playersarray, setPlayersArray] = useState<PlayersArray[]>([])
  const [showtxidloading, setShowTxidLoading] = useState<boolean>(true);
  const [tagordaddress, setTagOrdAddress] = useState<string>()
  const [activitymessage, setActivityMessage] = useState<string>("")
  const [txid, setTxid] = useState<string>()
  const [txidlink, setTxidLink] = useState<string>()
  const [tempactivity, setTempActivity] = useState<ActivityArray>()
  const [tempactivitywithtxid, setTempActivityWithTxid] = useState<ActivityArray>() 
  // const [finalactivity, setFinalActivity] = useState<ActivityArray>()
  const [activityarray, setActivityArray] = useState<ActivityArray[]>([{
    taggerowneraddress: "string",
    taggeroutpoint: "string",
    taggerfoxname: "string",
    taggerimage: "string",
    taggerimagelink: "string",
    taggeeowneraddress: "string",
    taggeeoutpoint: "string",
    taggeefoxname: "string",
    taggeeimage: "string",
    taggeeimagelink: "string",
    time: "string",
    txid: "string"
  }])
  const [chaserid, setChaserId] = useState<string>()
  const [whosit, setWhosIt] = useState<string>("NotIt")

  // const [message, setMessage] = useState<string>("awaiting reactions")
  // const [time, setTime] = React.useState('fetching')
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [height, setHeight] = useState<number>(window.innerHeight*.75)
  // const [width, setWidth] = useState<number>(1280)
  // const [height, setHeight] = useState<number>(720)

  // use navigate
  const navigate = useNavigate();
  //get bstate from search results
  const state = useLocation();

  const [sock, setSock] = useState<Socket>();


    //sounds
    const audio = useMemo(() => new Audio(rumble), [rumble]);
    //sounds
    const soundRef = useRef(false)


    function playboom() {
      // console.log("enterplayboom")
      // console.log(soundRef.current + " = soundref current")
      if(soundRef.current === true){
        // console.log(showmuted + " = showmuted in conditional")
        // console.log("shouldplayboom")
        new Audio(boom).play()
      }
    }
    function playrumble() {
      audio.play()
      soundRef.current = true;
      audio.addEventListener('ended', function(){
        return playrumble();
    })
      setShowMuted(false)
      setHideMuted(true)
    }
  
    function muterumble() {
      audio.pause()
      soundRef.current = false;
      setShowMuted(true)
      setHideMuted(false)
    }


  
    //playerpos
    const [rand1, setRand1] = useState<number>(Math.round((Math.floor(Math.random() * 5999) + 1) / 10) * 10);
    const [rand2, setRand2] = useState<number>(Math.round((Math.floor(Math.random() * 3999) + 1) / 10) * 10);
    const canvasRef = useRef(null);
    const [canvascontext, setCanvasContext] = useState(null);
  
    useEffect(() => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      setCanvasContext(context)
    }, []);

    useEffect(() => {
      if (sock) {
        sock.close();
      }
    }, [])






  //view
  // const [viewwidth, setViewWidth] = useState<number>(window.innerWidth);
  // const [viewheight, setViewHeight] = useState<number>(window.innerHeight);
  // const [viewwidth, setViewWidth] = useState<number>(1280);
  // const [viewheight, setViewHeight] = useState<number>(720);

  // get ord address
  useEffect(() => {
    if (!didMount1.current) {
      didMount1.current = true;
      return;
    }


  }, [tempactivity]);

  // get ord address
  useEffect(() => {
    if (!didMount3.current) {
      didMount3.current = true;
      return;
    }
    setTimeout(() => {
      setLoading(false)
      setLoaded(true)
    }, 2000);

  }, [foximageurl]);

  // handle action after txid 
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    if (tempactivity) {
      //adding to activity
      let jjj = tempactivity;
      jjj.txid = txid;
      setTempActivityWithTxid(jjj);
      let link = "https://whatsonchain.com/tx/" + txid;
      setTxidLink(link);
      // setShowTxidLoading(false)

      if (jjj.taggeeoutpoint === foximagesrc) {
        sock.close();
        navigate("/pickafox", { state: { owneraddress: state.state.owneraddress, tag: true, activity: tempactivity, txid: txid, date: tempactivity.time } });
      }
    }
    setActivityMessage("Restarting Game...")
    setTimeout(() => {
      // if (tempactivity.taggeeoutpoint === foximagesrc) {
      //   // sock.close()
      //   setViewingTaggedInfo(false)
      //   setShowLeaving(true)
      // }
      if (tempactivity.taggeeoutpoint != foximagesrc) {
        setShowInfo(true)
        setViewingTaggedInfo(false)
      }
      //clear txid
      setActivityMessage("")
      setTxid("")
      setTxidLink("")
      setShowTxidLoading(true)
    }, 10000);

  }, [txid]);





  useEffect(() => {
         setLoaded(true)
    // window.scrollTo(0, 0);
    // setMyOrdAddress(state.state.owneraddress)
    // setFoxesOwned(state.state.foxes)
    // setFoxName(state.state.foxname)
    // setFoxImageSrc(state.state.originoutpoint)
    // setFoxImageUrl("https://ordfs.network/content/" + state.state.originoutpoint)
    // setFoxImageInfo("https://alpha.1satordinals.com/outpoint/" + state.state.originoutpoint + "/inscription")
    // setTagOrdAddress("13m9K5GrXvLoyRaFoj6ooFWWm6kBHVHmgF")
  }, []);

  //disable arrow keys
  window.addEventListener("keydown", function (e) {
    if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
      e.preventDefault();
    }
  }, false);


  let playersArray = [];
  var chaserId;
  var newchaser = false;
  var latestactivity;

  //tag code

  useEffect(() => {
    if (!didMount2.current) {
      didMount2.current = true;
      return;
    }

    //draw bg to canvas context

    //assign background
    const background = new Image();
    background.src = "tagbg.jpg";
    // assign spaceship right
    const img2 = new Image();
    img2.src = "spaceship3.png";
    const img3 = new Image();
    img3.src = "spaceship3fast.png";


    // Make sure the image is loaded first otherwise nothing will draw.
    // background.onload = function(){
    //     canvasContext.drawImage(background,0,0);   
    // }



    //offline  
    // const socket = io('http://localhost:5000')
    //online
    const socket = io('https://foxlive-7ab7fefab994.herokuapp.com/')
    setSock(socket)
    // socket.on('connect', () => console.log(socket.id))
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 1000)
    })

    //declare game variable
    var Game: any = {};



    socket.on('connect', function () {
      setSock(socket)
      //tag code
      //initialize my player variable
      let thisPlayer: PlayersArray = {
        id: socket.id,
        owneraddress: "",
        outpoint: "",
        currentoutpoint: "",
        foxname: "",
        image: "",
        imagelink: "",
        x: rand1,
        y: rand2,
        speed: 400,
        height: 50,
        width: 50,
        dir: 1
      };
      // // console.log(thisPlayer);
      // socket.emit('playing', thisPlayer);
      
      // let thisPlayer=playersarray[0];
      //new player update playersArray
      socket.on('newPlayer', function (data) {
        //setplayersarray if changed
        setPlayersArray(data);
      });
      socket.on('awaitingActivity', function (data) {
       // console.log("awaiting activity")
        playboom()

        let temp: ActivityArray = data;
        setTempActivity(data);

        setShowInfo(false)
        setViewingTaggedInfo(true)
        setActivityMessage("Getting Transaction Id...")
        //get new position
        newchaser = true;
      //  console.log(newchaser)
      });

      socket.on('txid', function (data) {
       // console.log("received txid or error")
      //  console.log(data)
        let result = JSON.parse(data)
        if (result.txid) {
          setShowTxidLoading(false)
          setTxid(result.txid)
        } else {
       //   console.log("handle no txid error")
          setTxid(result.message)
        }
      });

      socket.on('alreadytagged', function () {
      //  console.log("already tagged, going home...")
        //see if next two lines work
        socket.close();
        goToIndex();
      });


      //new chaser
      socket.on('newChaser', function (id) {
        chaserId = id;
        setChaserId(chaserId)

        if (thisPlayer.id === chaserId) {
          setWhosIt("It")
        } else {
          setWhosIt("NotIt")
        }
      });

      //update players
      socket.on('updatePlayers', function (players) {
        playersArray = players;
        setPlayers(players.length)
      });

      // wrapper for "class" Rectangle
      (function () {
        function Rectangle(left, top, width, height) {
          this.left = left || 0;
          this.top = top || 0;
          this.width = width || 0;
          this.height = height || 0;
          this.right = this.left + this.width;
          this.bottom = this.top + this.height;
        }

        Rectangle.prototype.set = function (left, top, /*optional*/ width, /*optional*/ height) {
          this.left = left;
          this.top = top;
          this.width = width || this.width;
          this.height = height || this.height
          this.right = (this.left + this.width);
          this.bottom = (this.top + this.height);
        }

        Rectangle.prototype.within = function (r) {
          return (r.left <= this.left &&
            r.right >= this.right &&
            r.top <= this.top &&
            r.bottom >= this.bottom);
        }

        Rectangle.prototype.overlaps = function (r) {
          return (this.left < r.right &&
            r.left < this.right &&
            this.top < r.bottom &&
            r.top < this.bottom);
        }

        // add "class" Rectangle to our Game object
        Game.Rectangle = Rectangle;

      })();


      // wrapper for "class" Camera (avoid global objects)
      (function () {

        // possibles axis to move the camera
        var AXIS = {
          NONE: 1,
          HORIZONTAL: 2,
          VERTICAL: 3,
          BOTH: 4
        };

        // Camera constructor
        function Camera(xView, yView, viewportWidth, viewportHeight, worldWidth, worldHeight) {
          // position of camera (left-top coordinate)
          this.xView = xView || 0;
          this.yView = yView || 0;

          // distance from followed object to border before camera starts move
          this.xDeadZone = 0; // min distance to horizontal borders
          this.yDeadZone = 0; // min distance to vertical borders

          // viewport dimensions
          this.wView = viewportWidth;
          this.hView = viewportHeight;

          // allow camera to move in vertical and horizontal axis
          this.axis = AXIS.BOTH;

          // object that should be followed
          this.followed = null;

          // rectangle that represents the viewport
          this.viewportRect = new Game.Rectangle(this.xView, this.yView, this.wView, this.hView);

          // rectangle that represents the world's boundary (room's boundary)
          this.worldRect = new Game.Rectangle(0, 0, worldWidth, worldHeight);

        }

        // gameObject needs to have "x" and "y" properties (as world(or room) position)
        Camera.prototype.follow = function (gameObject, xDeadZone, yDeadZone) {
          this.followed = gameObject;
          this.xDeadZone = xDeadZone;
          this.yDeadZone = yDeadZone;
        }

        Camera.prototype.update = function () {
          // keep following the player (or other desired object)
          if (this.followed != null) {
            if (this.axis == AXIS.HORIZONTAL || this.axis == AXIS.BOTH) {
              // moves camera on horizontal axis based on followed object position
              if (this.followed.x - this.xView + this.xDeadZone > this.wView)
                this.xView = this.followed.x - (this.wView - this.xDeadZone);
              else if (this.followed.x - this.xDeadZone < this.xView)
                this.xView = this.followed.x - this.xDeadZone;

            }
            if (this.axis == AXIS.VERTICAL || this.axis == AXIS.BOTH) {
              // moves camera on vertical axis based on followed object position
              if (this.followed.y - this.yView + this.yDeadZone > this.hView)
                this.yView = this.followed.y - (this.hView - this.yDeadZone);
              else if (this.followed.y - this.yDeadZone < this.yView)
                this.yView = this.followed.y - this.yDeadZone;
            }

          }

          // update viewportRect
          this.viewportRect.set(this.xView, this.yView);

          // don't let camera leaves the world's boundary
          if (!this.viewportRect.within(this.worldRect)) {
            if (this.viewportRect.left < this.worldRect.left)
              this.xView = this.worldRect.left;
            if (this.viewportRect.top < this.worldRect.top)
              this.yView = this.worldRect.top;
            if (this.viewportRect.right > this.worldRect.right)
              this.xView = this.worldRect.right - this.wView;
            if (this.viewportRect.bottom > this.worldRect.bottom)
              this.yView = this.worldRect.bottom - this.hView;
          }

        }

        // add "class" Camera to our Game object
        Game.Camera = Camera;

      })();

      // wrapper for "class" Player
      (function () {
        function Player(x, y) {
          // (x, y) = center of object
          // ATTENTION:
          // it represents the player position on the world(room), not the canvas position
          this.x = x;
          this.y = y;



          // move speed in pixels per second
          this.speed = 300;




          // render properties
          this.width = 50;
          this.height = 50;
        }

        Player.prototype.update = function (step, worldWidth, worldHeight) {
          // parameter step is the time between frames ( in seconds )
          // double speed if space
          if (newchaser === true) {
            this.x = (Math.round((Math.floor(Math.random() * 3999) + 1) / 10) * 10);
            this.y = (Math.round((Math.floor(Math.random() * 3999) + 1) / 10) * 10);
          //  console.log(this.x, this.y)
            newchaser = false;
          }

          if (Game.controls.b === true) {
            this.speed = 800;
            thisPlayer.speed = 800;
          } else {
            this.speed = 300;
            thisPlayer.speed = 300;
          }

          // check controls and move the player accordingly
          if (Game.controls.left) {
            //face left
            thisPlayer.dir = -1;
            this.x -= this.speed * step;
          }

          if (Game.controls.up)
            this.y -= this.speed * step;
          if (Game.controls.right) {
            //face right
            thisPlayer.dir = 1;
            this.x += this.speed * step;
          }

          if (Game.controls.down)
            this.y += this.speed * step;

          // don't let player leaves the world's boundary
          if (this.x - this.width / 2 < 0) {
            this.x = this.width / 2;
          }
          if (this.y - this.height / 2 < 0) {
            this.y = this.height / 2;
          }
          if (this.x + this.width / 2 > worldWidth) {
            this.x = worldWidth - this.width / 2;
          }
          if (this.y + this.height / 2 > worldHeight) {
            this.y = worldHeight - this.height / 2;
          }


          //update player

          // if (newchaser === true){
          //   thisPlayer.x = newx;
          //   thisPlayer.y = newy;
          //   newchaser = false;
          // }else{
          thisPlayer.x = this.x;
          thisPlayer.y = this.y;
          // }


          //not updating
          // socket.emit('updatePlayer', thisPlayer);


        }

        Player.prototype.draw = function (context, xView, yView) {
          // draw a simple rectangle shape as our player model
          // context.save();
          // before draw we need to convert player world's position to canvas position			
          // context.fillRect((this.x - this.width / 2) - xView, (this.y - this.height / 2) - yView, this.width, this.height);

          // img.src = "android-chrome-192x192.png";


          //should not be creating new image eact time, store locally outside this function


          //rocket
          let rocket;
          if (thisPlayer.speed === 300) {
            rocket = img2;
          } else {
            rocket = img3;
          }
          // const img = new Image();
          // img.src = "https://ordfs.network/content/" + thisPlayer.outpoint;
          // if (thisPlayer.id === chaserId) {

            // context.strokeStyle = '#f00';  // some color/style
            // context.lineWidth = 3;         // thickness

          //   if (thisPlayer.dir === -1) {
          //     context.save();
          //     context.scale(-1, 1)
          //     context.shadowColor = '#FFEA00';
          //     context.shadowBlur = 50;
          //     context.shadowOffsetX = 0;
          //     context.shadowOffsetY = 0;
          //     context.drawImage(img, -(this.x - this.width / 2) + xView - 50, (this.y - this.height / 2) - yView, 50, 50);
          //     // context.strokeRect(-(this.x - this.width / 2) + xView-50, (this.y - this.height / 2) - yView, 50, 50);
          //     context.drawImage(rocket, -(this.x - this.width / 2) + xView - 150, (this.y - this.height / 2) - yView, 220, 110);
          //     context.restore();
          //   } else {
          //     context.save();
          //     context.shadowColor = '#FFEA00';
          //     context.shadowBlur = 50;
          //     context.shadowOffsetX = 0;
          //     context.shadowOffsetY = 0;
          //     context.drawImage(img, (this.x - this.width / 2) - xView, (this.y - this.height / 2) - yView, 50, 50);
          //     // context.strokeRect((this.x - this.width / 2) - xView, (this.y - this.height / 2) - yView, 50, 50);
          //     context.drawImage(rocket, (this.x - this.width / 2) - xView - 100, (this.y - this.height / 2) - yView, 220, 110);
          //     context.restore();
          //   }
          // } else {
          //   if (thisPlayer.dir === -1) {
          //     context.save();
          //     context.scale(-1, 1)
          //     context.drawImage(img, -(this.x - this.width / 2) + xView - 50, (this.y - this.height / 2) - yView, 50, 50);
          //     context.drawImage(rocket, -(this.x - this.width / 2) + xView - 150, (this.y - this.height / 2) - yView, 220, 110);
          //     context.restore();
          //   } else {
          //     context.drawImage(img, (this.x - this.width / 2) - xView, (this.y - this.height / 2) - yView, 50, 50);
          //     context.drawImage(rocket, (this.x - this.width / 2) - xView - 100, (this.y - this.height / 2) - yView, 220, 110);
          //   }


          // }
          // context.restore();

          //add other players
          playersArray.forEach(function (opponent) {

            const img = new Image();
            img.src = "https://ordfs.network/content/" + opponent.outpoint;
            // img.src = "android-chrome-192x192.png";
            let opprocket;
            if (opponent.speed === 300) {
              opprocket = img2;
            } else {
              opprocket = img3;
            }

            //temp
            // img.src = "android-chrome-192x192.png";

            if (opponent.id != thisPlayer.id) {


              if (opponent.id === chaserId) {
                context.strokeStyle = '#f00';  // some color/style
                context.lineWidth = 3;         // thickness


                // context.save();
                // context.scale(opponent.dir,1)
                if (opponent.dir === -1) {
                  context.save();
                  context.scale(-1, 1)
                  context.shadowColor = '#FFEA00';
                  context.shadowBlur = 50;
                  context.shadowOffsetX = 0;
                  context.shadowOffsetY = 0;
                  context.drawImage(img, -opponent.x + xView - 25, opponent.y - yView - 25, 50, 50);
                  // context.strokeRect(-opponent.x+xView-25, opponent.y-yView-25, 50, 50);
                  context.drawImage(opprocket, -opponent.x + xView - 125, opponent.y - yView - 25, 220, 110);
                  context.restore();

                } else {
                  context.save();
                  context.shadowColor = '#FFEA00';
                  context.shadowBlur = 50;
                  context.shadowOffsetX = 0;
                  context.shadowOffsetY = 0;
                  context.drawImage(img, opponent.x - xView - 25, opponent.y - yView - 25, 50, 50);
                  // context.strokeRect(opponent.x-xView-25, opponent.y-yView-25, 50, 50);
                  context.drawImage(opprocket, opponent.x - xView - 100 - 25, opponent.y - yView - 25, 220, 110);
                  context.restore();

                }

                // context.restore();
                //   context.drawImage(img2, opponent.x - xView-90, opponent.y - yView+10, 220, 110);
                //   canvascontext.strokeRect(opponent.x - xView, opponent.y - yView, 50, 50);
              } else {

                if (opponent.dir === -1) {
                  context.save();
                  context.scale(-1, 1)
                  context.drawImage(img, -opponent.x + xView - 25, opponent.y - yView - 25, 50, 50);
                  context.drawImage(opprocket, -opponent.x + xView - 125, opponent.y - yView - 25, 220, 110);
                  context.restore();

                } else {

                  context.drawImage(img, opponent.x - xView - 25, opponent.y - yView - 25, 50, 50);
                  context.drawImage(opprocket, opponent.x - xView - 100 - 25, opponent.y - yView - 25, 220, 110);


                }
                //   context.drawImage(img2, opponent.x - xView-90, opponent.y - yView+10, 220, 110);
              }
            }



          })

          // context.restore();
        }

        // add "class" Player to our Game object
        Game.Player = Player;

      })();

      // wrapper for "class" Map
      (function () {
        function Map(width, height) {
          // map dimensions
          this.width = width;
          this.height = height;

          // map texture
          this.image = background;
        }

        // creates a prodedural generated map (you can use an image instead)
        Map.prototype.generate = function () {
          var ctx = document.createElement("canvas").getContext("2d");
          ctx.canvas.width = this.width;
          ctx.canvas.height = this.height;

          // var rows = ~~(this.width / 44) + 1;
          // var columns = ~~(this.height / 44) + 1;

          // store the generate map as this image texture
          // this.image = new Image();
          // this.image.src = ctx.canvas.toDataURL("tagbg.png");

          // clear context
          ctx = null;
        }

        // draw the map adjusted to camera
        Map.prototype.draw = function (context, xView, yView) {
          // easiest way: draw the entire map changing only the destination coordinate in canvas
          // canvas will cull the image by itself (no performance gaps -> in hardware accelerated environments, at least)
          /*context.drawImage(this.image, 0, 0, this.image.width, this.image.height, -xView, -yView, this.image.width, this.image.height);*/

          // didactic way ( "s" is for "source" and "d" is for "destination" in the variable names):

          var sx, sy, dx, dy;
          var sWidth, sHeight, dWidth, dHeight;

          // offset point to crop the image
          sx = xView;
          sy = yView;

          // dimensions of cropped image			
          sWidth = context.canvas.width;
          sHeight = context.canvas.height;

          // if cropped image is smaller than canvas we need to change the source dimensions
          if (this.image.width - sx < sWidth) {
            sWidth = this.image.width - sx;
          }
          if (this.image.height - sy < sHeight) {
            sHeight = this.image.height - sy;
          }

          // location on canvas to draw the croped image
          dx = 0;
          dy = 0;
          // match destination with source to not scale the image
          dWidth = sWidth;
          dHeight = sHeight;

          context.drawImage(this.image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
        }

        // add "class" Map to our Game object
        Game.Map = Map;

      })();

      // Game Script
      (function () {
        // prepaire our game canvas
        // var canvas = document.getElementById("gameCanvas");
        // var canvas = canvasRef.current;

        // var context = canvascontext;


        // game settings:	
        var FPS = 30;
        var INTERVAL = 1000 / FPS; // milliseconds
        var STEP = INTERVAL / 1000 // seconds

        // setup an object that represents the room
        var room = {
          width: 6000,
          height: 4000,
          map: new Game.Map(6000, 4000)
        };

        // generate a large image texture for the room
        room.map.generate();

        // setup player
        var player = new Game.Player(rand1, rand2);

        // Old camera setup. It not works with maps smaller than canvas. Keeping the code deactivated here as reference.
        /* var camera = new Game.Camera(0, 0, canvas.width, canvas.height, room.width, room.height);*/
        /* camera.follow(player, canvas.width / 2, canvas.height / 2); */

        // Set the right viewport size for the camera
        // canvas.width = window.innerWidth;
        // canvas.height = window.innerHeight;
        // var vWidth = Math.min(room.width, width);
        // var vHeight = Math.min(room.height, height);
        var vWidth = Math.min(room.width, width);
        var vHeight = Math.min(room.height, height);

        // Setup the camera
        var camera = new Game.Camera(0, 0, vWidth, vHeight, room.width, room.height);
        camera.follow(player, vWidth / 2, vHeight / 2);

        // Game update function
        var update = function () {
          player.update(STEP, room.width, room.height);
          camera.update();
        }


        // Game draw function
        var draw = function () {
          // clear the entire canvas

          canvascontext.clearRect(0, 0, canvascontext.width, canvascontext.height);

          // redraw all objects
          room.map.draw(canvascontext, camera.xView, camera.yView);
          player.draw(canvascontext, camera.xView, camera.yView);

        }

        // Game Loop
        var gameLoop = function () {
          update();
          draw();
        }

        // <-- configure play/pause capabilities:

        // Using setInterval instead of requestAnimationFrame for better cross browser support,
        // but it's easy to change to a requestAnimationFrame polyfill.
        var runningId = -1;


        Game.play = function () {
          if (runningId == -1) {
            runningId = window.setInterval(function () {
              gameLoop();
            }, INTERVAL);
        //    console.log("play");
          }
        }

        // Game.togglePause = function() {
        //   if (runningId == -1) {
        Game.play();
        //   } else {
        //     clearInterval(runningId);
        //     runningId = -1;
        //     console.log("paused");
        //   }
        // }

        // -->

      })();

      // <-- configure Game controls:
      Game.controls = {
        left: false,
        up: false,
        right: false,
        down: false,
        b: false
      };

      window.addEventListener('keydown', function (event) {
        var key = event.key;

        if (key === 'w' || key === 'ArrowUp') {
          Game.controls.up = true;
        } else if (key === 's' || key === 'ArrowDown') {
          Game.controls.down = true;
        } else if (key === 'a' || key === 'ArrowLeft') {
          Game.controls.left = true;
        } else if (key === 'd' || key === 'ArrowRight') {
          Game.controls.right = true;
        } else if (key === 'b') {
          Game.controls.b = true;
        }
      })

      window.addEventListener('keyup', function (event) {
        var key = event.key;

        if (key === 'w' || key === 'ArrowUp') {
          Game.controls.up = false;
        } else if (key === 's' || key === 'ArrowDown') {
          Game.controls.down = false;
        } else if (key === 'a' || key === 'ArrowLeft') {
          Game.controls.left = false;
        } else if (key === 'd' || key === 'ArrowRight') {
          Game.controls.right = false;
        } else if (key === 'b') {
          Game.controls.b = false;
        } else if (key === 'p') {
          Game.togglePause();
        }
      })


    }) //end socket on connect




    // socket.on('time', (data) => setTime(data))
    // socket.on('message', (data) => getMessage(data))
    // socket.on('viewers', (data) => setViewers(data))

  }, [canvascontext]);

  //go home 
  const goToIndex = async () => {
    window.location.href = "https://foxplorer.org/";
  };


  const container = document.getElementById('circlesid');

  //launch fox
  const launchFox = (fox: string, src: string) => {
    let div = document.getElementById("circles");
    let li = document.createElement("li");
    li.innerHTML = `
    <li><img src='fox.png' /><br />` + fox + `</li>
    `
    div.append(li);
  };


  const getMessage = (data) => {
    if (data.reaction === "fox") {
      launchFox(data.fox, data.src);
    }
  };


  // const submitFox = () => {

  //   if (myordaddress === "") {
  //     alert('You must connect Yours Wallet and own a fox to react')
  //     return
  //   }
  //   if (myordaddress !== "" && ownsfox === false) {

  //     alert('You must own a fox to react')
  //     return
  //   }
  //   if (myordaddress !== "" && ownsfox === true) {
  //     sock.emit('message', { reaction: 'fox', fox: { foxname } })
  //   }

  // };
  // get ord address
  useEffect(() => {
    if (!didMount4.current) {
      didMount4.current = true;
      return;
    }

    setMyOrdAddress(addresses.ordAddress)

    navigate("/pickafox", { state: { owneraddress: addresses.ordAddress, tag: true } });

  }, [addresses]);


  //handle get adresses
  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  //handle connect
  const handleConnect = async () => {
    setLoading(true);
    setMyOrdAddress("")
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      setLoading(false);
      return;
    }

    const key = await wallet.connect();
    if (key) {
      setPubKey(key);
      setTimeout(() => {
        handleGetAddresses()
      }, 1000);

    }
    if (!key) {
      wallet.disconnect()
      alert("Make sure you are signed-in to Yours Wallet and try again.")
      setLoading(false);
      return
    }
  };


  return (

    <div className="App">
      <div id="Error"></div>

      {/* {preloaded && (
        <>
          <header className="App-header">

            <FoxplorerLogoHome onClick={goToIndex} />
            <div className="LoaderHeight">

              <ThreeCircles color="black" height="50"
                width="50" />
            </div>
            <MyOtherFox /> <MyFox />
          </header>
        </>
      )} */}

      <TemporaryDrawer />
      <div className="Topbar">
        <TagLogo onClick={goToIndex} />
        <a target="blank" href="https://twitter.com/yours_foxplorer"><XLogoRight /></a>
      </div>

      <div id="Live">
        <br />

{/* {!loaded && (
              <>
                {!loading && (
                  <>

                    <div className="Yours">
                      <FaucetPandaConnectButton onClick={handleConnect} />
                    </div>

                  </>

                )}
            {loading && (
              <>
                <span className="Required">
                  <ThreeCircles color="white" height="50"
                    width="50" />
                </span>
              </>
            )}
          </>
        )} */}


        {loaded && (
          <>
            <div className="Status">
              <span className="StatusLeft">


              <div className="Yours">
                      <FaucetPandaConnectButton onClick={handleConnect} />
                    </div>
              </span>
              <span className="StatusRight">

                <WeLive />
                <div className="TagPlayersContainer">
                  <div className="TagPlayers">
                  
                    {/* <AiOutlineEye size={33} /> {viewers}<br /> */}
                    {/* <span><AiOutlineEye size={33} /> {viewers}<br /></span> */}
              

                    Players: {players}
                  </div></div>
              </span>


            </div>

          </>
        )}


        {/* <div className="ViewersContainer">
            <div className="Viewers">
<AiOutlineEye size={33} /><span>  {viewers}</span>
</div></div> */}
        <div className="CenterCanvas">
                   {/* <canvas id="gameCanvas" ref={canvasRef} width={width} height={height} className="Canvas"></canvas>
 */}



          {/* {showleaving &&
            <div className="TaggedInfo">
              <h3>You Got Tagged</h3>
              <br />
              <div className="TaggedPlayers">
                <a target="blank" href={foximageinfo}>
                  <img
                    src={foximageurl}
                    className={whosit}
                    alt="Your Fox"
                  />
                </a>
              </div>
              <br />
              <p className="White">{foxname} Is Out Of Tag Forver</p>
              <p className="White">10 Million Foxes Can Play, But Only One Can Win</p>
              <p className="White">Click 'Exit Game' To Pick Another Fox</p>
              <ExitButton onClick={goToIndex} />
            </div>

          } */}

            <ImVolumeMute2 style={{ color: "#36bffa", display: showmuted ? "block" : "none" }} size={33} onClick={playrumble}/>
            <ImVolumeHigh style={{ color: "#36bffa", display: hidemuted ? "block" : "none" }} size={33} onClick={muterumble}/>
          

          {viewingtaggedinfo &&


            <div className="TaggedInfo">



              <h3>{tempactivity.taggerfoxname} tagged {tempactivity.taggeefoxname}</h3>
              <br />
              <div className="TaggedPlayers">
                <a target="blank"
                  href={tempactivity.taggerimagelink}>
                  <img className="ActivityIt" src={tempactivity.taggerimage}
                    id={tempactivity.taggeroutpoint} />
                </a>
                <a target="blank"
                  href={tempactivity.taggeeimagelink}>
                  <img className="ActivityNotIt" src={tempactivity.taggeeimage}
                    id={tempactivity.taggeeoutpoint} />
                </a>
              </div>
              <br />  <br />  <br />


              <p className="White">{activitymessage}</p>


      
                 <p className="White">
                <a className="White" target="blank" href={txidlink}><u>{txid}</u></a>
                </p>
         

              {showtxidloading &&

                <div className="LoaderHeight">
                  <ThreeCircles color="white" height="50"
                    width="50" />
                </div>
              }

            </div>

          }


          <div style={{ display: showinfo ? "block" : "none" }}>
          
          <p className="Blue">


          Use arrow keys. Press 'b' to go fast. New chaser every 30 seconds.</p>
          <canvas id="gameCanvas" ref={canvasRef} width={width} height={height} className="Canvas"></canvas>
        



          </div>



        </div>
        <br /><br />

        <div id="PlayerInfo">
        <h3>Players: {playersarray.length}</h3>
          <DisplayPlayersArray playersarray={playersarray} chaserid={chaserid} />
        
         
        </div>

  <TaggedFoxes latestactivity={tempactivitywithtxid} />

        <ul id='circles'>
        </ul>
      </div>

      <FooterHome />

    </div>


  )
};